export const BOTAO_CLICADO_SUCCESS = "BOTAO_CLICADO_SUCCESS";
export const BOTAO_CLICADO_REQUEST = "BOTAO_CLICADO_REQUEST";
export const BOTAO_CLICADO_FAILURE = "BOTAO_CLICADO_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_FAILURE = "REGISTER_FALIURE";
export const REGISTER_UPDATED_SUCCESS = "REGISTER_UPDATED_SUCCESS";
export const REGISTER_CREATED_SUCCESS = "REGISTER_CREATED_SUCCESS";

export const PERSIST_REHYDRATE = "persist/REHYDRATE";

export const REAL_ESTATE_DATA = "REAL_ESTATE_DATA";
export const NEW_CHECKLIST = "NEW_CHECKLIST";
export const NEW_CHECKLIST_PERGUNTAS_RESPOSTAS =
  "NEW_CHECKLIST_PERGUNTAS_RESPOSTAS";

export const RESET_STATE = "RESET_STATE";
