/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { PrivacyPolicySection } from "./styled";

export default function Politica() {
  return (
    <PrivacyPolicySection>
      <h2>Política de Privacidade</h2>
      <p>
        Esta política de privacidade se aplica ao aplicativo Checkpoint
        (doravante referido como "Aplicativo") para dispositivos móveis, que foi
        criado por Maurício Borges (doravante referido como "Provedor de
        Serviços") para uso exclusivo pessoal e interno da empresa. Este serviço
        é fornecido "COMO ESTÁ" e não está disponível ao público em geral.
      </p>
      <br />

      <h3>Coleta e Uso de Informações</h3>
      <ul>
        <li>
          O Aplicativo coleta apenas o nome e o e-mail ou telefone dos usuários
          para fins de login. Essas informações são usadas exclusivamente para
          permitir o acesso ao aplicativo e manter a conta do usuário ativa.
          Nenhuma outra informação pessoal é coletada ou armazenada pelo
          Aplicativo.
        </li>
        <li>
          O Aplicativo não coleta informações precisas sobre a localização do
          dispositivo, nem armazena ou processa dados adicionais além daqueles
          necessários para o login.
        </li>
        <li>
          As informações fornecidas para o login são mantidas de forma segura e
          não são compartilhadas com terceiros, exceto quando necessário para
          cumprimento de obrigações legais ou para garantir a segurança e
          integridade do Aplicativo.
        </li>
        <li>
          O Aplicativo não coleta informações precisas sobre a localização do
          seu dispositivo móvel.
        </li>
      </ul>
      <br />

      <h3>Acesso de Terceiros</h3>
      <p>
        O Aplicativo não compartilha nenhuma informação coletada com terceiros.
        Todas as informações fornecidas são utilizadas exclusivamente para o
        funcionamento do aplicativo e não são divulgadas a nenhuma outra
        organização ou serviço, exceto em casos de cumprimento de obrigações
        legais.
      </p>
      <p>
        O Provedor de Serviços pode divulgar Informações Fornecidas pelo Usuário
        e Informações Coletadas Automaticamente:
      </p>
      <ul>
        <li>
          conforme exigido por lei, como para cumprir uma intimação ou processo
          legal semelhante;
        </li>
        <li>
          quando o Provedor de Serviços acreditar de boa fé que a divulgação é
          necessária para proteger seus direitos, proteger sua segurança ou a
          segurança de outros, investigar fraude ou responder a uma solicitação
          do governo;
        </li>
        <li>
          com seus provedores de serviços confiáveis que trabalham em seu nome,
          não têm uso independente das informações que divulgamos a eles e
          concordaram em aderir às regras estabelecidas nesta declaração de
          privacidade.
        </li>
      </ul>
      <br />

      <h3>Direitos de Exclusão</h3>
      <p>
        Você pode interromper toda a coleta de informações pelo Aplicativo
        facilmente desinstalando-o. Você pode usar os processos padrão de
        desinstalação que podem estar disponíveis como parte do seu dispositivo
        móvel ou por meio do marketplace ou rede de aplicativos móveis.
      </p>
      <br />

      <h3>Política de Retenção de Dados</h3>
      <p>
        O Provedor de Serviços reterá os dados fornecidos pelo usuário enquanto
        o Aplicativo estiver em uso. Caso deseje solicitar a exclusão dos dados,
        entre em contato com o Provedor de Serviços pelo e-mail
        checkpoint.vix@gmail.com, e sua solicitação será atendida em um prazo
        razoável.
      </p>
      <br />

      <h3>Faixa Etária e Crianças</h3>
      <p>
        O Aplicativo é destinado exclusivamente a usuários com 18 anos ou mais.
        O Provedor de Serviços não coleta intencionalmente informações pessoais
        de menores de 18 anos. Caso seja identificado que um menor de 18 anos
        forneceu informações pessoais, essas serão excluídas imediatamente dos
        servidores.
      </p>
      <br />

      <h3>Segurança</h3>
      <p>
        O Provedor de Serviços se compromete a proteger a confidencialidade das
        informações dos usuários. Medidas adequadas são tomadas para garantir
        que os dados estejam seguros e protegidos contra acessos não
        autorizados.
      </p>
      <br />

      <h3>Alterações</h3>
      <p>
        Esta Política de Privacidade pode ser atualizada ocasionalmente.
        Quaisquer alterações serão publicadas nesta página, e o uso contínuo do
        Aplicativo após tais mudanças constitui sua aceitação da nova política.
      </p>
      <p>
        Esta política de privacidade é eficaz a partir de 26 de agosto de 2024.
      </p>
      <br />

      <h3>Seu Consentimento</h3>
      <p>
        Ao usar o Aplicativo, você consente com o processamento de suas
        informações conforme estabelecido nesta Política de Privacidade agora e
        conforme emendada por nós.
      </p>
      <br />

      <h3>Contato</h3>
      <p>
        Se você tiver alguma dúvida sobre privacidade ao usar o Aplicativo, ou
        tiver perguntas sobre as práticas, entre em contato com o Provedor de
        Serviços via e-mail em checkpoint.vix@gmail.com.
      </p>
      <hr />
    </PrivacyPolicySection>
  );
}
