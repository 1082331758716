export const primaryColor = "#0B3E2D"; // Verde escuro
export const primaryDarkColor = "#1A1A1D"; // Quase preto
export const buttonColor = "#C3073F"; // Vermelho vinho
export const backgroundColorCompany = "#C9A750"; // Dourado

// Cores atualizadas para melhor contraste
export const textColor = "#FFFFFF"; // Branco para texto, para contraste com fundos escuros
export const textLightColor = "#333333"; // Cinza escuro para texto, para contraste com fundos claros
export const linkColor = "#FFFFFF"; // Branco para links, para uso em fundos escuros
export const linkHoverColor = "#BBD1C1"; // Verde claro para hover
export const buttonHoverColor = "#9B1C31"; // Vermelho vinho um pouco mais escuro para hover de botões

export const sucessColor = "#0197F6"; // Azul para sucesso
export const infoColor = "#0197F6"; // Azul para informação
export const errorColor = "#F2AF29"; // Amarelo para erro
export const warningColor = "#F2AF29"; // Amarelo para aviso
