import React from "react";

import { Container } from "../../styles/GlobalStyles";

export default function SemAcesso() {
  return (
    <Container>
      <h1>Você não tem acesso</h1>
    </Container>
  );
}
